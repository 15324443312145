/* eslint-disable */
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Row, Select, Switch } from "antd";
import { find, filter, snakeCase } from "lodash";
import { useWidth } from "../../../../utils/useWidth";
import { configState } from "../../../../store/config/config-slice";
import { updateVendor } from "../../../../store/config/config-actions";

const VendorList = ({ filters, searchVendor }) => {
  const dispatch = useDispatch();
  const { width } = useWidth();
  const [filteredVendors, setFilteredVendors] = useState([]);
  const [vendorChanges, setVendorChanges] = useState({});
  const { manage_vendorList, isLoading } = useSelector(configState);

  useEffect(() => {
    const vendors = filter(manage_vendorList, { "is_enabled": 1 });
    const searchResult = vendors.filter(({ name, vendor_code }) => {
      const isString = isNaN(+searchVendor);
      if (isString) return name.toLowerCase().includes(searchVendor.toLowerCase());
      return vendor_code.includes(searchVendor);
    });
    setFilteredVendors(searchResult);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchVendor]);

  const headerStyle = { paddingRight: "5px", minWidth: width < 1200 && "80px" };

  const vendors = filter(manage_vendorList, { "is_enabled": 1 });

  return (
    <Row style={{ overflowX: "auto", maxWidth: "inherit" }}>
      <Row className="tx500 tx80" style={{ width: "-webkit-fill-available", flexWrap: "nowrap" }}>
        <Col flex="1" style={headerStyle}>
          Active
        </Col>
        <Col flex="1" style={headerStyle}>
          Vendor Code
        </Col>
        <Col flex="2" style={headerStyle}>
          Vendor Name
        </Col>

        <Col flex="1" style={headerStyle}>
          Vendor Group
        </Col>
        <Col flex="1" style={headerStyle}>
          {width > 1600 ? "Description" : "Desc."}
        </Col>

        <Col flex="1.5" style={headerStyle}>
          Source Type
        </Col>
        <Col flex="1" style={headerStyle}>
          Lead Day
        </Col>
        <Col flex="1" style={headerStyle}>
          Ordering Days
        </Col>
        <Col flex="1.5" style={headerStyle}>
          Delivery Days
        </Col>

        <Col flex="1" style={headerStyle}>
          MOQ
        </Col>
        <Col flex="1" style={headerStyle}>
          MOQ Type
        </Col>
        <Col flex="1" style={headerStyle}>
          MOQ Value
        </Col>
        <Col flex="1" style={headerStyle}>
          Action
        </Col>
      </Row>

      {!isLoading && !manage_vendorList.length && !filteredVendors.length && (
        <Row className="tx400 tx75" style={{ fontSize: "small", paddingTop: "1rem" }}>
          No record found.
        </Row>
      )}

      {(searchVendor.length ? filteredVendors : vendors).map((vendor) => (
        <Row
          key={vendor.id}
          className="tx400"
          style={{
            fontSize: "small",
            padding: "0.25rem 0",
            width: "-webkit-fill-available",
            alignItems: "baseline",
            flexWrap: "nowrap",
          }}
        >
          {[
            "is_enabled",
            "vendor_code",
            "name",
            "vendor_group",
            "description",
            "source_type",
            "delivery_leadtime_day",
            "ordering_day",
            "delivery_day",
            "moq",
            "moq_type",
            "moq_value",
            "action",
          ].map((x, ind) => (
            <Col
              key={vendor.id + x}
              flex={
                x === "name"
                  ? "2"
                  : ["action"].includes(x)
                  ? "50px"
                  : x === "source_type"
                  ? "1.25"
                  : x === "delivery_day"
                  ? "1.5"
                  : "1"
              }
              style={{
                width: "100px",
                paddingRight: "5px",
                minWidth: width < 1200 && "80px",
              }}
            >
              {x === "is_enabled" ? (
                <Switch
                  size="small"
                  defaultChecked={vendor.is_enabled}
                  style={{ width: "30px" }}
                  onChange={(e) => {
                    const existing = vendorChanges.hasOwnProperty(vendor.id)
                      ? vendorChanges[vendor.id]
                      : vendor;
                    setVendorChanges({
                      ...vendorChanges,
                      [vendor.id]: { ...existing, [x]: e },
                    });
                  }}
                />
              ) : x === "source_type" ? (
                <Select
                  showSearch
                  showArrow
                  // options={["DTS", "CH"].map((x) => ({ value: snakeCase(x), label: x }))}
                  options={["DTS", "VCH", "SCH"].map((x) => ({
                    value: snakeCase(x),
                    label: x,
                  }))}
                  name="source_type"
                  style={{ padding: "0 0.25rem", width: "95%" }}
                  className="tx75"
                  defaultValue={vendor["source_type"]}
                  onChange={(e) => {
                    const existing = vendorChanges.hasOwnProperty(vendor.id)
                      ? vendorChanges[vendor.id]
                      : vendor;
                    setVendorChanges({
                      ...vendorChanges,
                      [vendor.id]: { ...existing, [x]: e },
                    });
                  }}
                />
              ) : [
                  "description",
                  "delivery_leadtime_day",
                  "ordering_day",
                  "delivery_day",
                  "moq",
                  "moq_type",
                  "moq_value",
                ].includes(x) ? (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "70%",
                    borderBottom: "1px solid #000",
                  }}
                >
                  <input
                    type="text"
                    defaultValue={vendor[x]}
                    className="tx75 reset"
                    onChange={(e) => {
                      {
                        const existing = vendorChanges.hasOwnProperty(vendor.id)
                          ? vendorChanges[vendor.id]
                          : vendor;
                        setVendorChanges({
                          ...vendorChanges,
                          [vendor.id]: { ...existing, [x]: e.target.value },
                        });
                      }
                    }}
                  />
                </Row>
              ) : ["action"].includes(x) ? (
                <Row
                  style={{
                    justifyContent: "center",
                    width: "1",
                    borderBottom:
                      ["min_qty_in_mfc", "max_qty_in_mfc"].includes(x) && "1px solid #000",
                  }}
                >
                  <Button
                    className="c1 tx75"
                    onClick={() => {
                      const target = find(vendorChanges, (e) => e.id === vendor.id);
                      if (!target) return alert("no changes on this vendor");
                      dispatch(updateVendor(target));
                    }}
                  >
                    Submit
                  </Button>
                </Row>
              ) : (
                <Row style={{ width: "70%" }} className="tx75">
                  {vendor[x]}
                </Row>
              )}
            </Col>
          ))}
        </Row>
      ))}
    </Row>
  );
};

export default VendorList;
