import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { vendorState } from "../../../store/vendors/vendor-slice";
import { warehouseState } from "../../../store/warehouse/warehouse-slice";
import { snakeCase, sortBy, find, flatMap, upperCase, capitalize, startCase, isNumber } from "lodash";
import { simulationState } from "../../../store/simulation/simulation-slice";
import { Row, Col } from "antd";
import GsSelect, { GsButton, GsRangePicker2 } from "../../atoms/customForm";
import { downloadReport } from "../../../store/report/report-actions";
import { useRouteMatch } from "react-router-dom";
import { datatableState } from "../../../store/datatable/datatable-slice";
import sendNotification from "../../../utils/sendNotification";
import { skuState } from "../../../store/sku/sku-slice";
import { ProgressBar } from "../../atoms/ProgressBar";
import { reportState } from "../../../store/report/report-slice";
import getCategories from "../../../store/categories/categories-actions";
import { categoriesState } from "../../../store/categories/categories-slice";
import getVendorList from "../../../store/vendors/vendor-actions";
import moment from "moment";

const DownloadSimulation = ({ onSuccess }) => {
  const { path } = useRouteMatch();
  const dispatch = useDispatch();
  const { vendorList } = useSelector(vendorState);
  const { warehouseList } = useSelector(warehouseState);
  const { selectedItem } = useSelector(datatableState);
  const { selectedSkuInfo } = useSelector(skuState);
  const { isLoading } = useSelector(reportState);
  const { categoryList } = useSelector(categoriesState);

  const {
    activeSimulation: { mfc, vendor_code },
  } = useSelector(simulationState);
  const onDateChange = (dates) => {
    setFormData({ ...formData, date_range: dates });
  };

  const customLabelMapping = {
    remaining: "Remaining Inventory",
    ka_placed_to_pack_qty: "KA Placed Order Qty",
    mom_qty: "MOM Qty",
  };

  const key_figure_options = [
    "all_key_figures",
    "demand",
    "daily_forecast",
    "placed_order",
    "ka_placed_to_pack_qty",
    "demand_adjustment",
    "total_supply",
    "ideal_incoming",
    "supply_adjustment",
    "incoming_orders",
    "intransit_orders",
    "ideal_order_qty",
    "remaining",
    // "max_stock_qty",
    "min_qty_in_mfc",
    "over_stock",
    "under_stock",
    "opening_inventory",
    "bulk_order_qty_pcs",
    "bulk_order_qty",
    "mom_qty",
    "total_inventory",
    "ending_days_cover",
    "lost_sales",
  ].map((x) => ({ value: snakeCase(x), label: customLabelMapping[x] ?? startCase(x) }));

  const allSelectedSKU = flatMap(Object.values(selectedItem))?.map((x) => ({
    value: selectedSkuInfo?.find(({ id }) => id === x)?.sku_code,
  }));
  const initialDateRange = [moment(), moment().add(12, "days")];
  const [formData, setFormData] = useState({
    mfc: warehouseList.find(({ code }) => mfc === code)?.code || null,
    vendor: vendorList.filter(({ code }) => vendor_code?.includes(code)).map(({ id }) => id) || [],
    // key_figure_options.map((x) => x.value),
    key_figure: ["ending_days_cover", "ideal_order_qty", "ideal_incoming", "lost_sales", "opening_inventory"],
    file_type: "csv",
    status: "all",
    selected_sku: allSelectedSKU.map(({ value }) => value),
    category: [],
    // source_type: ["dts", "ch"],
    source_type: ["dts", "vch", "sch"],
    uom: "qty",
    date_range: initialDateRange,
  });

  useEffect(() => {
    setFormData({
      ...formData,
      selected_sku: allSelectedSKU.map(({ value }) => value),
      key_figure: ["ending_days_cover", "ideal_order_qty", "ideal_incoming", "lost_sales", "opening_inventory"],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  useEffect(() => {
    dispatch(getCategories(formData.mfc));
    dispatch(getVendorList(formData.mfc));
    setFormData({ ...formData, category: [], vendor: [] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.mfc]);

  const onChange = (value) => {
    if (value?.key_figure?.includes("all_key_figures")) {
      return setFormData({
        ...formData,
        key_figure: ["all_key_figures"],
      });
    }
    if (value?.selected_sku?.includes("All selected sku")) {
      return setFormData({
        ...formData,
        selected_sku: allSelectedSKU.map(({ value }) => value),
      });
    } else {
      setFormData({ ...formData, ...value });
    }
  };

  const onSubmit = () => {
    const isAllkeys = formData.key_figure.includes("all_key_figures");
    const all_keys = key_figure_options.flatMap((x) => (x.value !== "all_key_figures" ? x.value : []));

    if (!formData.mfc) {
      return sendNotification("Uncomplete form fields", "MFC are required", "error");
    }

    const payload = {
      warehouse_code: [formData.mfc],
      columns: isAllkeys ? all_keys : formData.key_figure,
      file_type: formData.file_type,
      uom: formData.uom.toUpperCase(),
      start_date: formData.date_range[0].format("YYYY-MM-DD"),
      end_date: formData.date_range[1].format("YYYY-MM-DD"),
    };

    if (!formData.status.match(/all/i)) payload.status = upperCase(formData.status);

    if (path !== "/home" && formData.selected_sku.length) {
      payload.sku_id = formData.selected_sku.map((x) => selectedSkuInfo?.find(({ sku_code }) => sku_code === x)?.id);
    }
    if (formData.vendor.length > 0) {
      const serializeId = (id) => (isNumber(id) ? id : +id?.split("^")[0]);

      payload.vendor_code = formData.vendor.map(
        (x) => find(vendorList, ({ id }) => +serializeId(x) === +id)?.vendor_code
      );
    }
    if (path === "/home") payload.category = formData.category;
    if (path === "/home") payload.source_type = formData.source_type;
    dispatch(downloadReport(payload, onSuccess));
  };

  return (
    <Row justify="center" align="middle" style={{ height: "100%" }}>
      <Col style={{ margin: "0.5rem 0", alignItems: "flex-start" }}>
        <Row justify="center" className="tx550" style={{ fontSize: "1.1rem", paddingBottom: "1.5rem" }}>
          Download Report
        </Row>

        <Col style={{ maxHeight: "70vh", overflow: "auto" }}>
          <GsSelect
            label="Key Figure"
            value={formData.key_figure}
            options={key_figure_options}
            onChange={onChange}
            style={{ marginBottom: "1rem", width: "320px" }}
            multiple
          />

          {path === "/supply_plan/:id" && (
            <>
              <GsSelect
                label="selected_sku"
                options={[{ value: "All selected sku" }, ...allSelectedSKU]}
                onChange={onChange}
                style={{ marginBottom: "1rem", width: "320px" }}
                value={formData.selected_sku}
                multiple
              />
              {/* <GsRangePicker2 style={{ marginBottom: "1rem", width: "320px" }} disabledDate= {(current) => current && current < moment().startOf("day")}
          label="Date Range"
          defaultValue={formData.date_range}
          onChange={onDateChange}          
        /> */}
            </>
          )}

          {path === "/home" && (
            <>
              <GsSelect
                label="MFC"
                options={sortBy(warehouseList, ["value"])}
                onChange={onChange}
                style={{ marginBottom: "1rem", width: "320px" }}
                value={formData.mfc}
              />

              <GsSelect
                label="Vendor"
                options={sortBy(
                  vendorList.map(({ label, id }) => ({ label, value: `${id}^${label}` })),
                  ["label"]
                )}
                onChange={onChange}
                multiple
                style={{ marginBottom: "1rem", width: "320px" }}
                value={formData.vendor}
              />
              <GsSelect
                label="Category"
                value={formData.category}
                options={categoryList.map((c) => ({ label: c, value: c }))}
                onChange={onChange}
                style={{ marginBottom: "1rem", width: "320px" }}
                multiple
                disabled={!categoryList.length}
              />

              <GsSelect
                label="source_type"
                value={formData.source_type}
                // options={["DTS", "CH"].map((x) => ({ value: snakeCase(x), label: x }))}
                options={["DTS", "VCH", "SCH"].map((x) => ({ value: snakeCase(x), label: x }))}
                onChange={onChange}
                style={{ marginBottom: "1rem", width: "320px" }}
                multiple
              />

              {/* <RangePicker
            label="Date Range"
            value={formData.date_range}
            onChange={onDateChange}
            disabledDate={(current) => current && current < moment().startOf("day")}
            style={{
              width: "100%",
              padding: "0.5rem",
              borderRadius: "4px",
              border: "1px solid #ccc",
            }}
          /> */}
              {/* <GsRangePicker2 style={{ marginBottom: "1rem", width: "320px" }} disabledDate= {(current) => current && current < moment().startOf("day")}
          label="Date Range"
          defaultValue={formData.date_range}
          onChange={onDateChange}          
        /> */}
            </>
          )}
          <GsRangePicker2
            style={{ marginBottom: "1rem", width: "320px" }}
            disabledDate={(current) => current && current < moment().startOf("day")}
            label="Date Range"
            defaultValue={formData.date_range}
            onChange={onDateChange}
          />

          <GsSelect
            label="uom"
            value={formData.uom}
            options={[
              { value: "qty", label: "Qty" },
              { value: "cbm", label: "CBM" },
              { value: "case", label: "Case" },
              { value: "pesos", label: "Peso" },
            ]}
            onChange={onChange}
            style={{ marginBottom: "1rem", width: "320px" }}
          />

          <GsSelect
            label="Status"
            options={["all", "todo", "finalized"].map((x) => ({
              value: snakeCase(x),
              label: capitalize(x),
            }))}
            onChange={onChange}
            style={{ marginBottom: "1rem", width: "320px" }}
            value={formData.status}
          />

          <GsSelect
            label="File Type"
            options={["XLSX", "CSV"].map((x) => ({ value: snakeCase(x), label: x }))}
            onChange={onChange}
            style={{ marginBottom: "1rem", width: "320px" }}
            defaultValue={formData.file_type}
          />
        </Col>
        <Row justify="center">
          <GsButton
            label="Confirm"
            onClick={onSubmit}
            type="primary"
            style={{ padding: "0.75rem", width: "220px" }}
            offset="1.25rem"
          />
        </Row>
      </Col>
      <ProgressBar show={isLoading} />
    </Row>
  );
};

export default DownloadSimulation;
