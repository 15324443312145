import { addDays, addWeeks, endOfDay, closestTo } from "date-fns";
import { isMonday, isTuesday, isWednesday, isThursday, isFriday } from "date-fns";
import { nextMonday, nextTuesday, nextWednesday, nextThursday } from "date-fns";
import { nextFriday, nextSaturday, nextSunday, isSaturday, isSunday } from "date-fns";
import { lowerCase, toUpper, startCase, map, join } from "lodash";
import { format } from "date-fns";

export const getNextTargetDays = (dateList, startDate = new Date(), daysCount = 28) => {
  const nextTargetDay = (target = "mon", addedWeek = 0) => {
    const nextDate = {
      mon: nextMonday,
      tue: nextTuesday,
      wed: nextWednesday,
      thu: nextThursday,
      fri: nextFriday,
      sat: nextSaturday,
      sun: nextSunday,
    };
    return addWeeks(nextDate[lowerCase(target)](startDate), addedWeek);
  };
  const closesDate = () => {
    if (dateList.includes("0/" + toUpper(format(startDate, "EEE")))) {
      return new Date();
    } else {
      const dates = [];
      dateList.map((d) => dates.push(nextTargetDay(d.split("/")[1], d.split("/")[0])));
      return closestTo(new Date(), dates);
    }
  };

  // CHECK next weeks
  const dateChecker = (targetDay) => {
    const dateCheckerFunc = {
      mon: isMonday,
      tue: isTuesday,
      wed: isWednesday,
      thu: isThursday,
      fri: isFriday,
      sat: isSaturday,
      sun: isSunday,
    };
    return dateCheckerFunc[lowerCase(targetDay)];
  };

  const editableDates = [];
  dateList
    .map((d) => d.split("/")[1])
    // eslint-disable-next-line array-callback-return
    .map((x) => {
      let startingDate = closesDate();
      const endDate = endOfDay(addDays(startingDate, 28));
      while (startingDate <= endDate) {
        if (dateChecker(x)(startingDate)) {
          editableDates.push(format(new Date(startingDate), "dd-MMM-yy"));
        }
        startingDate = addDays(startingDate, 1);
      }
    });
  return editableDates;
};

export const transformData = (arrays) => {
  const target = [
    "demand",
    "daily_forecast",
    "placed_order",
    "ka_placed_to_pack_qty",
    "demand_adjustment",
    "total_supply",
    "ideal_incoming",
    "supply_adjustment",
    "incoming_orders",
    "intransit_orders",
    "ideal_order_qty",
    "bulk_order_qty",
    "bulk_order_qty_pcs",
    "remaining",
    "max_stock_qty",
    "min_stock_qty",
    "mom_qty",
    "opening_inventory",
    "total_inventory",
    "ending_days_cover",
    "over_stock",
    "under_stock",
    "lost_sales",
  ];

  const customLabelMapping = {
    remaining: "Remaining Inventory",
    ka_placed_to_pack_qty: "KA Placed Order Qty",
    mom_qty: "MOM Qty",
  };

  const result = {};

  arrays.forEach((arr) => {
    target.map(async (key) => {
      const date = format(new Date(arr["date"]), "dd-MMM-yy");
      result[key] = {
        ...result[key],
        key_figure: customLabelMapping[key] ?? startCase(key),
        [date]: arr[key],
        key,
      };
    });
  });

  return result;
};

export function deliveryDaysFormat(string) {
  const hasPipe = string?.delivery_day.includes("|");
  if (hasPipe) {
    const weekdays = string.delivery_day.split("|");
    const weekdaysWithPrefix = map(weekdays, (day) => `0/${day}`);
    const outputString = join(weekdaysWithPrefix, ",");
    return outputString;
  } else {
    return string.delivery_day;
  }
}
