import { API_URI } from "../../config";
import { removeForage } from "../api/forage";
import { checkError, requestOption } from "../api/request";
import { configAction } from "./config-slice";
import sendNotification from "../../utils/sendNotification";
import { sortBy, snakeCase } from "lodash";

const getManageVendorList = ({ warehouse_code }) => {
  return async (dispatch) => {
    const { setManage_vendorList, setIsLoading } = configAction;
    dispatch(setIsLoading(true));

    if (!warehouse_code) return;

    try {
      const query = `?warehouse_code=${warehouse_code}`;

      const { data } = await requestOption(`${API_URI}/admin/v1/vendor${query}`, false, {
        record: false,
      });
      dispatch(setManage_vendorList(data));
      dispatch(setIsLoading(false));
    } catch (error) {
      checkError(error);
      dispatch(setIsLoading(false));
    }
  };
};

export const updateVendor = (newValue) => {
  return async (dispatch, getItem) => {
    const { setIsLoading, setSearchVendor, setManage_vendorList } = configAction;
    dispatch(setIsLoading(true));

    try {
      await requestOption(`${API_URI}/admin/v1/vendor`, newValue, {
        type: "PUT",
        refetch: true,
        record: false,
      });
      sendNotification("Success!", "Vendor updated successfully", "success");
      removeForage(`vendor?warehouse_code=${newValue.warehouse_code}`);
      if (!newValue.is_enabled) {
        const currentVendors = getItem()?.config?.manage_vendorList || [];
        const vendors = currentVendors.filter((v) => v.id !== newValue.id);
        dispatch(setSearchVendor(snakeCase("")));
        dispatch(setManage_vendorList(vendors));
      }
      dispatch(setIsLoading(false));
    } catch (error) {
      checkError(error);
      dispatch(setIsLoading(false));
    }
  };
};

export const uploadNewVendor = (csv_file, onSuccess) => {
  return async (dispatch) => {
    const { setIsLoading } = configAction;
    dispatch(setIsLoading(true));

    var myHeaders = new Headers();
    myHeaders.append("Authorization", localStorage.getItem("token"));

    var formdata = new FormData();
    formdata.append("file", csv_file, csv_file.name);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URI}/admin/v1/upload_vendor_file`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        if (result.match(/duplicate/i)) {
          sendNotification("Duplicate entry", JSON.parse(result).data.split("[")[0], "error");
        } else {
          sendNotification("Success!", JSON.parse(result).data, "success");
          onSuccess();
        }
        dispatch(setIsLoading(false));
      })
      .catch((error) => {
        checkError(error);
        dispatch(setIsLoading(false));
      });
  };
};

export const getManageAccountList = () => {
  return async (dispatch) => {
    const { setManage_accountList, setIsLoading } = configAction;
    dispatch(setIsLoading(true));

    try {
      const isLead = localStorage.getItem("role")?.match(/lead/i);
      const { data } = await requestOption(`${API_URI}/admin/v1/accounts`, false, {
        record: false,
      });
      const accounts = isLead ? data.filter((x) => x.user_type.match(/planner/i)) : data;
      const updatedWH = accounts.map((d) => ({
        ...d,
        warehouse_code: d?.warehouse_code.split(","),
      }));
      const sortByName = sortBy(updatedWH, ["gs_account"]);
      dispatch(setManage_accountList(sortByName));
      dispatch(setIsLoading(false));
      return sortByName;
    } catch (error) {
      checkError(error);
      dispatch(setIsLoading(false));
    }
  };
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    const { setIsLoading } = configAction;
    dispatch(setIsLoading(true));

    const { warehouse_code, gs_account, gs_account_id, user_type, is_enabled } = account;

    try {
      const payload = {
        gs_account,
        gs_account_id,
        user_type,
        is_enabled,
        warehouse: warehouse_code.join(),
      };
      await requestOption(`${API_URI}/admin/v1/accounts`, payload, {
        type: "PUT",
        record: false,
      });

      removeForage(`accounts`);
      sendNotification("Success!", "Account updated successfully", "success");
      dispatch(setIsLoading(false));
    } catch (error) {
      checkError(error);
      dispatch(setIsLoading(false));
    }
  };
};

export default getManageVendorList;
